<template>
  <div id="details" v-if="info">
    <a-page-header title="返回" @back="() => $router.go(-1)" />
    <h1 class="post_title">
      {{ info.title }}
    </h1>
    <p style="color:#666666;font-size:18px;padding: 20px 0;">
      <span>{{ info.group == null ? "" : info.group.name }} </span>
      <span style="margin-left:10px"> {{ getDate(info.create_time) }} </span>
    </p>
    <div class="num-box">
      <div class="read">
        <div>
          {{ info.post_hits }}
        </div>
        <div>
          阅读数
        </div>
      </div>
      <div class="collect">
        <div>
          {{ info.post_favorites }}
        </div>
        <div>
          收藏数
        </div>
      </div>
      <div class="like">
        <div>
          {{ info.post_like }}
        </div>
        <div>
          点赞数
        </div>
      </div>
      <div class="comment">
        <div>
          {{ info.comment_count }}
        </div>
        <div>
          评论数
        </div>
      </div>
    </div>
    <div class="videoContent">
      <div class="box-left">
        <p>
          视频预览
        </p>
        <video class="vid" :src="info.video_url" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="box-right">
        <p>
          视频截图
        </p>
        <div class="img-box">
          <img :src="item" v-for="(item, index) in thumb_list" :key="index" />
        </div>
      </div>
    </div>
    <a-table
      style="width:1520px; margin-bottom:30px;background: #fff;"
      rowKey="id"
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
    >
    </a-table>
  </div>
</template>

<script>
import { videoBackDetaill } from "@/apis/index";
import moment from "moment";
const columns = [
  {
    title: "医生",
    dataIndex: "users",
    key: "users",
    scopedSlots: { customRender: "users" },
    customRender: (text, record) => {
      return text.user_nickname;
    },
    width: 200,
  },
   {
    title: "职称",
    dataIndex: "professional_name",
    key: "professional_name",
    scopedSlots: { customRender: "professional_name" },
    customRender: (text, record) => {
      return record.users.professional_name;
    },
    width: 200,
  },
  {
    title: "所在医院",
    dataIndex: "table_name",
    key: "table_name",
    scopedSlots: { customRender: "table_name" },
    customRender: (text, record) => {
      return record.users.hospital;
    },
    width: 200,
  },
  {
    title: "评论时间",
    dataIndex: "create_time",
    key: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
    width: 200,
  },
  {
    title: "最新评论",
    dataIndex: "content",
    key: "content",
    scopedSlots: { customRender: "content" },
  },
];
export default {
  data() {
    return {
      columns,
      info: "",
      tableData: [],
      thumb_list: [],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#1890FF", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
  created() {
    videoBackDetaill({ id: this.$route.query.id, num: 10 }).then((res) => {

      if (res.code == 1) {
        this.info = res.data.info;
        this.tableData = res.data.comment_list;
        this.thumb_list = res.data.thumb_list;
      }
    });
  },
  methods: {
    getDate(timer) {
      return this.$moment(Number(timer + "000")).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang="less" scoped>
#details {
  // background: #F9F9F9;
  padding: 20px;
  .post_title {
    color: #333333;
    font-size: 24px;
    margin-top: 20px;
  }
  .videoContent {
    margin: 30px 0;
    width: 1520px;
    display: flex;
    justify-content: space-between;
    & > div {
      box-sizing: border-box;
      width: 665px;
      height: 488px;
      background: #fff;

      p {
        width: 100%;
        line-height: 80px;
        color: #333333;
        font-size: 22px;
        font-weight: bold;
        text-indent: 2rem;
      }
    }
    .box-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .vid {
        width: 591px;
        height: 366px;
      }
    }
    .box-right {
      width: 780px;
      .img-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        img {
          width: 234px;
          height: 165px;
          border-radius: 5px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .num-box {
    display: flex;
    .collect {
      background-image: url("../../../assets/img/detail/collect.png");
    }
    .like {
      background-image: url("../../../assets/img/detail/like.png");
    }
    .comment {
      background-image: url("../../../assets/img/detail/comment.png");
    }
    & > div {
      width: 297px;
      height: 114px;
      background-image: url("../../../assets/img/detail/read.png");
      margin-right: 20px;
      box-sizing: border-box;
      color: #3a383b;
      padding: 30px 0;
      background-repeat: no-repeat;
      & > div {
        width: 200px;
        margin-left: 100px;
        text-align: center;
        line-height: 30px;
        font-size: 20px;
      }
    }
  }
}
</style>
